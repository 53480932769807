import React from 'react'
import Loan from "../Components/Loan"
import convehimg from "../assets/convimg.jpg"

const Constequloan = () => {
  return (
    <div>
      <Loan loanname="Construction Equipment Loan" getloanname="Get Construction VehEquipmentcile Loan" loansubheading="Buy Your construction Equipment with Our Flexible and Affordable Construction Equipment Loans" loanimg={convehimg} whatisloanname="What is Construction equipment loan?" loandefinition="A construction equipment loan is a type of financing that is specifically designed to help businesses purchase heavy equipment for construction purposes. This type of loan is often secured by the equipment being purchased, and may have longer repayment terms than other types of loans. Some lenders may require a down payment or collateral to secure the loan." />
    </div>
  )
}

export default Constequloan