import { useState, useEffect } from "react";
import { Slider, SliderTrack, SliderFilledTrack, SliderThumb, Box } from '@chakra-ui/react';

import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";

const Calculator = () => {

    const [loanAmount, setLoanAmount] = useState(0);
    const [interestRate, setInterestRate] = useState(0);
    const [loanTerm, setLoanTerm] = useState(1);
    const [emi, setEmi] = useState(0);
    const [totalInterest, setTotalInterest] = useState(0);
    const [totalPrincipal, setTotalPrincipal] = useState(0);


    const calculateEmi = () => {
        const r = interestRate / (12 * 100);
        const n = loanTerm * 12; // convert loan term from years to months
        const emi = (loanAmount * r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1);
        const totalInterest = emi * n - loanAmount;
        const totalPrincipal = loanAmount + totalInterest;
        setEmi(emi);
        setTotalInterest(totalInterest);
        setTotalPrincipal(totalPrincipal);
    };

    useEffect(() => {
        calculateEmi();
    }, [loanAmount, interestRate, loanTerm]);


    return (
        <div className='h-screen bg-[#fbfcfd]'>
            <Navbar />
            <div className='max-w-[1300px] mx-auto  '>
                <div className=" flex gap-[8px] pt-8 pl-4">
                    <Link className="text-[16px] font-[650] text-[#465364]" to="/">Home</Link>
                    <FiChevronRight color="#465364" size={20} strokeWidth="3px" className="mt-[2px]" />
                    <Link className="text-[16px] font-[650] text-[#465364]" to="/Calculator">Calculator</Link>
                </div>
                <div className='text-center md:text-[36px] text-[32px] font-[500] text-[#111536] leading-[48px] mt-14'> Loan EMI Calculator</div>
                <div className='md:max-w-[900px] mc:max-w-[800px] sm:max-w-[650px] esm:max-w-[500px] max-w-[350px] bg-white mx-auto mc:h-[660px] sm:h-[620px] h-[660px] mt-10 shadow'>
                    <div className=' flex sm:justify-around sm:flex-row flex-col'>

                        <div className="flex flex-col sm:mt-20 m-[20px] mc:p-10 p-5 rounded-lg gap-12 bg-[#fbfcfd]">
                            <div className="flex flex-col gap-4">
                                <div className="flex justify-between">
                                    <label className="esm:text-[16px] text-[14px] font-[600] leading-[32px] text-[#011a48]">Loan Amount</label>

                                    <div className="flex">
                                        <input className="text-center" type="number" value={loanAmount} onChange={(event) => setLoanAmount(parseInt(event.target.value))} />
                                        <p className="font-[500] relative right-[90px] text-[#111536] text-[15px] top-[5px] "> ₹</p>
                                    </div>

                                </div>
                                <Slider defaultValue={1} min={100000} max={1000000} step={1} onChange={(value) => setLoanAmount(value)}>
                                    <SliderTrack height="2" >
                                        <SliderFilledTrack bg="orange.400" />
                                    </SliderTrack>
                                    <SliderThumb boxSize={4}>
                                        <Box color='tomato'
                                        />
                                    </SliderThumb>
                                </Slider>
                            </div>
                            <div className="flex flex-col gap-4">
                                <div className="flex justify-between">
                                    <label className="esm:text-[16px] text-[14px] font-[600] leading-[32px] text-[#011a48]">Rate of Interest (P.a)</label>
                                    <div className="flex">
                                        <input className="text-start relative left-[5px]" type="number" value={interestRate} onChange={(event) => setInterestRate(parseFloat(event.target.value))} />
                                        <p className="relative font-[600] right-[24px] text-[#111536] text-[15px] top-[5px]"> %</p>
                                    </div>
                                </div>
                                <Slider defaultValue={1.0} min={0.5} max={20.0} step={0.1} onChange={(value) => setInterestRate(value)}>
                                    <SliderTrack height="2" >
                                        <SliderFilledTrack bg="orange.400" />
                                    </SliderTrack>
                                    <SliderThumb boxSize={4}>
                                        <Box color='tomato'
                                        />
                                    </SliderThumb>
                                </Slider>
                            </div>
                            <div className="flex flex-col gap-4">
                                <div className="flex justify-between">
                                    <label className="esm:text-[16px] text-[14px] font-[600] leading-[32px] text-[#011a48]">Loan Tenure (Years)</label>

                                    <div className="flex">
                                        <input className="text-start relative left-[28px] " type="number" value={loanTerm} onChange={(event) => setLoanTerm(parseFloat(event.target.value))} placeholder="Years" />
                                        <p className="relative font-[500] right-[22px] text-[#111536] esm:text-[15px] text[11px] top-[4px]">Years</p>
                                    </div>
                                </div>
                                <Slider defaultValue={1} min={1} max={30} step={0.5} onChange={(value) => setLoanTerm(value)}>
                                    <SliderTrack height="2" >
                                        <SliderFilledTrack bg="orange.400" />
                                    </SliderTrack>
                                    <SliderThumb boxSize={4}>
                                        <Box color='tomato'
                                        />
                                    </SliderThumb>
                                </Slider>
                            </div>
                        </div>

                        <div className="my-auto md:pr-16 relative md:right-16  sm:pr-4 pt-6 mc:px-0 mx-auto flex flex-col gap-4">
                            <div className="flex justify-between">
                                <label className="font-[400] text-[rgba(1,26,72,.6)] text-[16px]"> Monthly EMI</label>
                                <div className="text-[#111536] font-[700]">
                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;   ₹ {emi.toFixed(1)}
                                </div>
                            </div>
                            <div className="flex justify-between">

                                <label className="font-[400] text-[rgba(1,26,72,.6)] text-[16px]">Total Interest</label>
                                <div className="text-[#111536] font-[700]">
                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;   ₹ {totalInterest.toFixed(1)}
                                </div>

                            </div>
                            <div className="flex justify-between">

                                <label className="font-[400] text-[rgba(1,26,72,.6)] text-[16px]">Total Amount</label>
                                <div className="text-[#111536] font-[700]">
                                    ₹  {totalPrincipal.toFixed(1)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-[#ff5732] sm:h-[117px] h-[100px] mt-12 text-center text-[#ffffff] flex flex-col gap-2 ">
                        <div className="mc:text-[27px] pt-[12px] text-[22px] font-[500]">
                            Your Monthly Loan EMI is
                        </div>
                        <div className="mc:text-[30px] text-[26px] font-[600]">
                            ₹ {emi.toFixed(1)}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-5 content-center md:px-28 sm:px-24 px-12 sm:py-[100px] py-[70px] ">
                    <p className="md:text-[36px] sm:text-[32px] text-[24px] font-[700] leading-[48px] text-[#111536]">
                        What is a  Loan EMI Calculator?
                    </p>
                    <p className="md:text-[18px] sm:text-[16px] text-[14px] text-[#6b6b6b]">
                        A loan EMI calculator helps you calculate the amount of loan instalment or EMI (Equated Monthly Instalment) that you would have to pay towards repaying your home loan. This easy-to-use online EMI calculator also helps you plan and manage your finances better.
                    </p>
                    <p className="md:text-[18px] sm:text-[16px] text-[14px] text-[#6b6b6b]">
                        LoanWallah loan EMI calculator carries a user-friendly design and is extremely easy to use. All you have to do is enter the loan amount you wish to borrow, tenure, and interest rate. You shall have the EMI and total interest payable on the loan amount in a few seconds.
                    </p>

                    <p className="md:text-[18px] sm:text-[16px] text-[14px] text-[#6b6b6b]">
                        Please note that the Home Loan EMI calculator has been created to give you an approximate understanding and should not be considered as absolute.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Calculator