import React from 'react'
import loanarr from '../assets/loanarr.svg'
import Stats from '../Components/Stats'
import { Link } from 'react-router-dom'

const Products = () => {
  return (
    <div id="products">
      <div>
        <h1 className='title'> Our Products</h1>
      </div>
      <Link to="/Homeloan" className='flex flex-wrap justify-center gap-12 px-10'>
        <div className='box flex flex-col justify-between hover:bg-purple-100 hover:border-black hover:border-[3px]'>
          <Link className=''>
            <h1 className='text-black text-[24px] font-[600]'>Home Loan</h1>
            <p className='text-[#465364] pt-5 font-[500]'>Get easy Home Loan to build your dream home</p>
          </Link>
          <img className='h-10 w-10' src={loanarr} alt="" />
        </div>
        <Link to="/Personalloan" className='box flex flex-col justify-between  hover:bg-purple-100 hover:border-black border-[3px]'>
          <div className=''>
            <h1 className='text-black text-[24px] font-[600]'>Personal Loan</h1>
            <p className='text-[#465364] pt-5 font-[500]'>Take personal loan and fulfill all your dreams</p>
          </div>
          <img className='h-10 w-10' src={loanarr} alt="" />
        </Link>
        <Link to="/Carloan" className='box flex flex-col justify-between  hover:bg-purple-100 hover:border-black border-[3px]'>
          <div className=''>
            <h1 className='text-black text-[24px] font-[600]'>Car Loan</h1>
            <p className='text-[#465364] pt-5 font-[500]'>Get easy car Loan to buy  your dream home</p>
          </div>
          <img className='h-10 w-10' src={loanarr} alt="" />
        </Link>
        <Link to="/Educationloan" className='box flex flex-col justify-between hover:bg-purple-100 hover:border-black border-[3px]'>
          <div className=''>
            <h1 className='text-black text-[24px] font-[600]'>Education Loan</h1>
            <p className='text-[#465364] pt-5 font-[500]'>Get easy Education and Loan Empower your future</p>
          </div>
          <img className='h-10 w-10' src={loanarr} alt="" />
        </Link>
        <Link to="/Mortageloan" className='box flex flex-col justify-between  hover:bg-purple-100 hover:border-black border-[3px]'>
          <div className=''>
            <h1 className='text-black text-[24px] font-[600]'>Mortage Loan</h1>
            <p className='text-[#465364] pt-5 font-[500]'>Get easy Mortage Loan and unlock your dream home</p>
          </div>
          <img className='h-10 w-10' src={loanarr} alt="" />
        </Link>
        <Link to="/Businessloan" className='box flex flex-col justify-between  hover:bg-purple-100 hover:border-black border-[3px]'>
          <div className=''>
            <h1 className='text-black text-[24px] font-[600]'>Business Loan</h1>
            <p className='text-[#465364] pt-5 font-[500]'>Get easy Home Loan and fuel your business dream </p>
          </div>
          <img className='h-10 w-10' src={loanarr} alt="" />
        </Link>
        <Link to="/Goldloan" className='box flex flex-col justify-between  hover:bg-purple-100 hover:border-black border-[3px]'>
          <div className=''>
            <h1 className='text-black text-[24px] font-[600]'>Gold Loan</h1>
            <p className='text-[#465364] pt-5 font-[500]'>Get easy Gold Loan and unlock the value of gold</p>
          </div>
          <img className='h-10 w-10' src={loanarr} alt="" />
        </Link>
        <Link to="/Commvloan" className='box flex flex-col justify-between  hover:bg-purple-100 hover:border-black border-[3px]'>
          <div className=''>
            <h1 className='text-black text-[21px] font-[600]'>Commerical Vehicle Loan</h1>
            <p className='text-[#465364] pt-4 font-[500]'>Get easy Home Loan to build your dream home</p>
          </div>
          <img className='h-10 w-10' src={loanarr} alt="" />
        </Link>
        <Link to="/Constequloan" className='box flex flex-col justify-between  hover:bg-purple-100 hover:border-black border-[3px]'>
          <div className=''>
            <h1 className='text-black text-[21px] font-[600]'>Construction Equipment Loan</h1>
            <p className='text-[#465364] pt-5 font-[500]'>Get easy Home Loan to build your dream home</p>
          </div>
          <img className='h-10 w-10' src={loanarr} alt="" />
        </Link>
      </Link>
    
    </div>
  )
}

export default Products