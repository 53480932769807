import React from 'react'
import educloan from "../assets/educloan.jpg"
import carloan from "../assets/carloan.jpg"
import mortageloan from "../assets/mortageloan.png"

import Banner from './Banner'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";

const Header = () => {
  return (
    <div className='bg-[#f5f5f5]'>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide><Banner loanName="Car Loan" loanDescription="Take car Loan at very low interst rate" bannerimg={carloan} />
        </SwiperSlide>
        <SwiperSlide><Banner loanName="Mortage Loan" loanDescription="Take Mortage Loan at very low interst rate" bannerimg={mortageloan} /></SwiperSlide>
        <SwiperSlide><Banner loanName="Education Loan" loanDescription="Take Study Loan at very low interst rate" bannerimg={educloan} /></SwiperSlide>
      </Swiper>
    </div>
  )
}

export default Header

