import React from 'react'
import Loan from "../Components/Loan"
import commvehloan from "../assets/commvehloan.webp"
const Commvloan = () => {
  return (
    <div>
      <Loan loanname="Commerical Vehicle Loan" getloanname="Get Commerical Vehcile Loan" loansubheading="Buy Your Commerical vehicle with Our Flexible and Affordable Commerical Vehcile Loan" loanimg={commvehloan} whatisloanname="What is Commerical vehicle loan?" loandefinition="A commercial vehicle loan is a type of financing that is specifically designed to help businesses purchase vehicles for commercial purposes, such as delivery vans or trucks. This type of loan is often secured by the vehicle being purchased, and may have longer repayment terms than other types of loans. Some lenders may require a down payment or collateral to secure the loan." />
    </div>
  )
}
export default Commvloan