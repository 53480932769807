import React from 'react'
import Navbar from "../Components/Navbar";
import { Link } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";



const About = () => {
    return (
        <div><div className='h-screen bg-[#fbfcfd]'>
            <Navbar />
            <div className='max-w-[1300px] mx-auto  '>
                <div className=" flex gap-[8px] pt-8 pl-4">
                    <Link className="text-[16px] font-[650] text-[#465364]" to="/">Home</Link>
                    <FiChevronRight color="#465364" size={20} strokeWidth="3px" className="mt-[2px]" />
                    <Link className="text-[16px] font-[650] text-[#465364]" to="/about">About Us</Link>
                </div>

                <div className='m-7 text-[25px] font-[500]'>Overview</div>

                <div className='text-[18px] mc:mx-20 pb-16 my-10 mx-20 '>

                    "LoanWallah is a financial services company dedicated to helping individuals and businesses secure loans from banks. Our team of experienced professionals works closely with clients to understand their financial needs and goals, and then helps them navigate the loan application process from start to finish.
                    <br/>
                    <br/>
                    <br />
                    At LoanWallah, we believe that everyone should have access to the financial resources they need to achieve their dreams. We work with a network of top-tier banks to provide our clients with a range of loan options, and we pride ourselves on providing excellent customer service and support throughout the entire process.
                    <br />
                    <br />
                    Whether you're looking to finance a new home, start a business, or fund a personal project, LoanWallah is here to help. Contact us today to learn more about our services and how we can help you achieve your financial goals."
                </div>
            </div>


            <div>


            </div>
        </div>
        </div>
    )
}

export default About