import React from 'react'
import Loan from "../Components/Loan"
import homeloan2 from "../assets/homeloan2.jpg"

const Homeloan = () => {
  return (
    <div>
      <Loan loanname="Home Loan" getloanname="Get Home Loan" loansubheading="Unlock Your Homeownership Dreams with Our Flexible and Affordable Home Loans" loanimg={homeloan2} whatisloanname="What is home loan?" loandefinition="A home loan is a loan you can apply for to purchase a home or property. It is a secured loan, meaning you have to pledge collateral. Just like any other loan, you can repay your housing loan in easy EMIs (Equated Monthly Instalments). Getting a house loan can also help you save on your taxes.The property remains as security with the lender for the term or period of the loan.You may avail a home loan for purposes like Purchase Residental Property, Home Expansion, Home Contruction, Acquisition of Land, Home Renovation and Balance Transfer." />
    </div>
  )
}

export default Homeloan