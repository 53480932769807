import React from 'react'
import Loan from "../Components/Loan"
import perloan from "../assets/perloan.png"

const Personalloan = () => {
  return (
    <div>
      <Loan loanname="Personal Loan" getloanname="Get Personal Loan" loansubheading="Unlock Your all Dreams with Our Flexible and Affordable Personal Loans" loanimg={perloan} whatisloanname="What is Personal loan?" loandefinition="A personal loan is a type of loan that you can use for any purpose, such as consolidating debt, paying for a wedding, or making home improvements. It's usually an unsecured loan, which means you don't have to put up collateral like your home or car. The interest rates and terms of a personal loan depend on your credit score, income, and other factors." />
    </div>
  )
}

export default Personalloan