import React from 'react'

const Videos = () => {
  return (
    <div className='mb-10 bg-[#f8fafc]'>
      <h1 className='pt-4 ems:title titleesm'>Explore our videos</h1>
      <div className='flex flex-row flex-wrap justify-center gap-10'>
        <div className='' >
          <iframe className='rounded-lg' width="320" height="180" src="https://www.youtube.com/embed/Z1qiTZdsFHs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          <p className='w-[320px] videocontent pt-4'>How to apply for home loan at SBI? Step by setp process explained | LoanWallah</p>
        </div>
        <div>
          <iframe className='rounded-lg' width="320" height="180" src="https://www.youtube.com/embed/hOZ0_mlXog8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          <p className='w-[320px] videocontent pt-4'>Everything you need to know about Pradhanmantri Awas Yojna | LoanWallah</p>
        </div>
        <div>
          <iframe className='rounded-lg' width="320" height="180" src="https://www.youtube.com/embed/Z7Q-WG1HyKs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          <p className='w-[320px] videocontent pt-4'>Everything you need to know about home loan | LoanWallah</p>
        </div>
      </div>

      <div className='flex justify-center py-16'>
        <a href="https://www.youtube.com/@Loan_Wallah"><button  className=" flex gap-3 text-[#ffffff] px-12 items-center  py-[12px] rounded-3xl text-[20px] font-[700]  bg-[#FF5732] ">
          View all 
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 font-[700]">
            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
          </svg>

        </button>
        </a>
      
      </div>
    </div>
  )
}

export default Videos