import React from 'react'
import Loan from "../Components/Loan"
import goldloan from "../assets/goldloan.png"


const Goldloan = () => {
  return (
    <div>
      <Loan loanname="Gold Loan" getloanname="Get Gold Loan" loansubheading="Unlock Your Gold Dreams with Our Flexible and Affordable Gold Loans" loanimg={goldloan} whatisloanname="What is Gold loan?" loandefinition="A Gold Loan, also known as a Loan against Gold refers to a lump sum of money borrowed by offering to pledge Gold. A secured loan by nature, the borrower needs to deposit gold articles, jewelry, coins, or any physical form of gold with the lender until the entire loan amount gets repaid. The amount the borrower sanctions depends on the value of the gold based on the Loan-to-Value (LTV) Ratio." />
    </div>
  )
}

export default Goldloan