import React from 'react'
import axisbank from '../assets/axixbank.png'
import bandhanbank from '../assets/bandhanbank.png'
import bob from '../assets/BankofBaroda.png'
import bankofmaharashtra from '../assets/BankofMaharashtra.png'
import canarabank from '../assets/CanaraBank.png'
import dcbbank from '../assets/DCBBank.png'
import hdfc from '../assets/HDFC.png'
import ujjiban from '../assets/ujjibanbank.jpg'
import utkarsh from '../assets/utkarshbank.jpg'
import idbi from '../assets/IDBI.png'
import indianoverseas from '../assets/IndianOverseasBank.png'
import sbi from '../assets/SBI.png'
import indianbank from '../assets/IndianBank.png'

const Partners = () => {
    return (
        <>
            <h1 id='partners' className='title'>Our Partners</h1>
            <div className='flex flex-wrap w-full mc:ml-10 pt-4 ml-10 mc:px-36 px-16 mc:gap-20 gap-10 pb-5' >
                <div >
                    <img className='h-[70px] w-[170px]' src={bob} alt="" />
                </div>
                <div>
                    <img className='h-[70px] w-[170px]' src={indianbank} alt="" />
                </div>
                <div>
                    <img className='h-[70px] w-[170px]' src={sbi} alt="" />
                </div>
                <div>
                    <img className='h-[70px] w-[170px]' src={indianoverseas} alt="" />
                </div>
                <div>
                    <img className='h-[70px] w-[170px]' src={idbi} alt="" />
                </div>
                <div>
                    <img className='h-[70px] w-[170px]' src={utkarsh} alt="" />
                </div>
                <div>
                    <img className='h-[70px] w-[170px]' src={ujjiban} alt="" />
                </div>
                <div>
                    <img className='h-[70px] w-[170px]' src={hdfc} alt="" />
                </div>
                <div>
                    <img className='h-[70px] w-[170px]' src={dcbbank} alt="" />
                </div>
                <div>
                    <img className='h-[70px] w-[170px]' src={canarabank} alt="" />
                </div>
                <div>
                    <img className='h-[70px] w-[170px]' src={bankofmaharashtra} alt="" />
                </div>
                {/* <div>
                <img className='h-[70px] w-[170px]' src={bandhanbank} alt="" />
            </div>   */}
                <div>
                    <img className='h-[70px] w-[170px]' src={axisbank} alt="" />
                </div>




            </div>
        </>
    )
}

export default Partners