import React from 'react'
import service from '../assets/service.svg'
import secure from '../assets/secure.svg'
import paperless from '../assets/paperless.svg'



const WhyLoanwallah = () => {
  return (
      <div className='mx-auto max-w-[1200px] pt-[30px]' >
          <div className='text-center text-[44px] text-[#201528] font-[500] leading-[43px]'>
            Why Loanwallah?
        </div>
          <div className='text-center text-[16px] text-[#465364] font-[500] leading-7 mt-[24px]'>
              LoanWallah is a digital app that provides simple, affordable, and accessible financial products and services.
        </div>
        <div className='flex justify-between mt-[46px]'>
            <div className=''>
                <img src={paperless} alt="paperless" className='mx-auto h-[40px] w-[40px]' />
                  <div className=' text-center text-[18px] font-[400] text-[#111536] mt-[20px] leading-7'>100% Paperless Process</div>
            </div>
              <div>
                  <img src={paperless}  alt="minumn documentation" className='mx-auto h-[40px] w-[40px]' />
                  <div className=' text-center text-[18px] font-[400] text-[#111536] mt-[20px] leading-7'>Minimum Documentation required</div>
              </div>
              <div>
                  <img src={secure} alt="secure" className=' mx-auto h-[40px] w-[40px]' />
                  <div className=' text-center text-[18px] font-[400] text-[#111536] mt-[20px] leading-7'>100% safe and secure</div>
              </div>
              <div>
                  <img src={service} alt="servce" className='mx-auto h-[40px] w-[40px]' />
                  <div className=' text-center text-[18px] font-[400] text-[#111536] mt-[20px] leading-7'>24%7 service available</div>
              </div>
        </div>
    </div>
  )
}

export default WhyLoanwallah