import React from 'react'

const banner = ({ loanName, loanDescription, bannerimg }) => {
    return (
        <div className='w-full mc:px-28 px-20'>
            <div className=' flex items-center sm:flex-row flex-col  justify-between'>
                <div className='my-auto '>
                    <div  className='esm:pt-0 pt-10 mc:text-[40px] sm:text-[36px] text-[28px] text-[#3C0050] font-[500]'>{loanName}</div>
                    <div className='text-[#6b6b6b] mc:text-[20px] text-[14px] font-[450]'>{loanDescription}</div>
                    <div className='pt-6'>
                        <button className='text-[#ffffff] esm:px-[50px] esm:py-[13px] px-[30px] py-[9px] rounded-full text-[24px] font-semibold  bg-[#FF5732]  '>
                            Apply Now
                        </button>
                    </div>
                </div>
                <div className='py-[90px]'>
                    <img src={bannerimg} alt="NOT present" className='md:h-[400px] md:w-[500px] esm:h-[327px] esm:w-[319px] h-[300px] w-[300px]' />
                </div>
            </div>
        </div>
    )
}

export default banner