import React from 'react'
import loanwalogo2 from '../assets/loanwlogo2.png'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='md:px-40 esm:px-32  px-10 '>
      <div className='flex flex-wrap justify-around'>
        <div>
          <img className='h-12 w-[200px]' src={loanwalogo2} alt="" />
          <h1 className='footernavi pt-2'>Loanwallah Private Ltd.</h1>
        </div>
        <div>
          <ul className='list-none'>
            <li><h1 className='footerhead'>Company</h1></li>
            <li><Link to='/About' className='footersubhead'>About us</Link></li>
            <li><a href='#partners' className='footersubhead'>Partners</a></li>
            <li><h1 className='footersubhead'>Contact us</h1></li>
            <li><a href='#portfolio' className='footersubhead'>Portfolio</a></li>
          </ul>
        </div>
        <div>
          <ul className='list-none'>
            <li><Link to='/' className='footerhead'>Products</Link></li>
            <li><Link to='/Homeloan' className='footersubhead'>Home Loan</Link></li>
            <li><Link to='/Personalloan' className='footersubhead'>Personal Loan</Link></li>
            <li><Link to='/Carloan' className='footersubhead'>Car Loan</Link></li>
            <li><Link to='/Educationloan' className='footersubhead'>Education Loan</Link></li>
            <li> <Link to='/Mortageloan' className='footersubhead'>Mortage Loan</Link></li>
          </ul>
        </div>
        <div>
          <ul className='list-none'>
            <li><Link to='/' className='footerhead pt-5'></Link></li>
            <li><Link to='/Businessloan' className='footersubhead'>Business Loan</Link> </li>
            <li> <Link to='/Goldloan' className='footersubhead'>Gold Loan</Link></li>
            <li> <Link to='/Commvloan' className='footersubhead'>Commerical Vehicle Loan</Link></li>
            <li> <Link to='/Constequloan' className='footersubhead'>Construction Equipment Loan</Link> </li>
            <li><Link to='/Calculator' className='footersubhead'>Calculator</Link></li>
          </ul>
        </div>
      </div >
      <div className='h-[1px] bg-gray-400 w-full mt-8'></div>
      <p className='footerend py-2'>All information is subject to specific conditions | © 2023 LoanWallah Private Ltd. All rights are reserved.</p>
      <div className='h-[1px] mb-1 bg-gray-400 w-full'></div>
    </div >
  )
}

export default Footer