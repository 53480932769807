import { Route, Routes } from 'react-router-dom';
import './App.css';
import Calculator from './Components/Calculator';
import WhyLoanwallah from './Components/WhyLoanwallah';
import About from './pages/About';
import Home from './pages/Home';
import Homeloan from './pages/Homeloan';
import Personalloan from './pages/Personalloan';
import Carloan from './pages/Carloan'
import Educationloan from './pages/Educationloan'
import Mortageloan from './pages/Mortageloan'
import Businessloan from './pages/Businessloan'
import Goldloan from './pages/Goldloan'
import Commvloan from './pages/Commvloan'
import Constequloan from './pages/Constequloan'
import Partners from './Components/Partners';

function App() {
  return (
    <div className='w-full'>
      <Routes>
        <Route path='/' element={<Home />} exact></Route>
        <Route path='/Calculator' element={<Calculator />} exact></Route>
        <Route path='/WhyLoanwallah' element={<WhyLoanwallah />} exact></Route>
        <Route path='/Footer' element={<Home />} exact></Route>
        <Route path='/About' element={<About />} exact></Route>
        <Route path='/Homeloan' element={<Homeloan  />} exact></Route>
        <Route path='/Carloan' element={<Carloan  />} exact></Route>
        <Route path='/Educationloan' element={<Educationloan  />} exact></Route>
        <Route path='/Mortageloan' element={<Mortageloan  />} exact></Route>
        <Route path='/Businessloan' element={<Businessloan  />} exact></Route>
        <Route path='/Goldloan' element={<Goldloan  />} exact></Route>
        <Route path='/Commvloan' element={<Commvloan  />} exact></Route>
        <Route path='/Constequloan' element={<Constequloan  />} exact></Route>
        <Route path='/Personalloan' element={<Personalloan  />} exact></Route>
        <Route path='/Partners' element={<Partners  />} exact></Route>
      </Routes>
    </div>
  );
}

export default App;
