import React from 'react'

const Stats = () => {
    return (
        <div id='portfolio' className='bg-[#3c0050] pb-10 mt-8'>
            <h1 className='py-12 text-center title-font sm:text-4xl text-3xl font-medium text-white'>Our Portfolio</h1>
            <section className="text-white body-font">
                <div className="container px-5 mx-auto">
                    <div className="flex flex-wrap -m-4 text-center">
                        <div className="p-4 sm:w-1/4 w-1/2">
                            <h2 className="title-font font-medium md:text-4xl text-3xl text-white ">200 Cr+</h2>
                            <p className="leading-relaxed">Loan Amount Disbursed</p>
                        </div>
                        <div className="p-4 sm:w-1/4 w-1/2">
                            <h2 className="title-font font-medium sm:text-4xl text-3xl text-white ">600</h2>
                            <p className="leading-relaxed">Total Customers</p>
                        </div>
                        <div className="p-4 sm:w-1/4 w-1/2">
                            <h2 className="title-font font-medium sm:text-4xl text-3xl text-white ">12</h2>
                            <p className="leading-relaxed">States</p>
                        </div>
                        <div className="p-4 sm:w-1/4 w-1/2">
                            <h2 className="title-font font-medium sm:text-4xl text-3xl text-white ">2400</h2>
                            <p className="leading-relaxed ">Citites</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}

export default Stats