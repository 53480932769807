import React from 'react'
import Loan from "../Components/Loan"
import mortageloan from "../assets/mortageloan.png"


const MortageLoan = () => {
  return (
    <div>
      <Loan loanname="Mortage Loan" getloanname="Get Mortage Loan" loansubheading="Unlock Your  Dream home with Our Flexible and Affordable Mortage Loans" loanimg={mortageloan} whatisloanname="What is Mortage loan?" loandefinition="A mortgage loan is a type of loan used to purchase or refinance a home or other real estate property. It's typically a long-term loan, usually with a repayment period of 15 or 30 years. The loan is secured by the property itself, and the lender has the right to foreclose on the property if the borrower fails to make payments. The loan amount, interest rate, and terms depend on the borrower's creditworthiness, income, and the property's value." />
    </div>
  )
}

export default MortageLoan