import React from 'react'
import { Link } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import HomeLoanStep from '../assets/HomeLoanStep.png'
import hero from '../assets/hero.png';
import Check_round_fill from '../assets/Check_round_fill.png';
import Footer from '../Components/Footer';
import Partners from '../Components/Partners';
import Stats from '../Components/Stats';
import Navbar from '../Components/Navbar';
import educloan from "../assets/educloan.jpg"

const EducationLoan = () => {
  return (
    <div className='h-screen'>
      <div>
        <Navbar />
      </div>
      <div className=''>
        <div className='bg-[rgba(245,245,245,245)]'>
          <div className='max-w-[1300px] mx-auto'>
            <div className=" flex gap-[8px] pt-8 pl-10">
              <Link className="text-[16px] font-[650] text-[#3c0050]" to="/">Home</Link>
              <FiChevronRight color="#465364" size={20} strokeWidth="3px" className="mt-[2px]" />
              <Link className="text-[16px] font-[650] text-[#3c0050]" to="/Calculator">Education loan</Link>
            </div>
            <section className="text-gray-600 body-font">
              <div className="container mx-auto flex px-5 py-12 md:flex-row flex-col items-center">
                <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                  <h1 className="title-font sm:text-[45px] text-[35px] mb-4 text-[#3c0050]  font-[500] sm:leading-[48px]"> Get Education Loan
                    <br />
                  </h1>
                  <p className="mb-8 text-[16px] leading-6 text-[rgba(107,107,107,107)] ">Unlock Your all Dreams with Our Flexible and <br /> Affordable Personal Loans</p>
                  <div className="flex justify-center">
                    <button className=" text-[#ffffff] px-8 py-[12px] rounded-2xl text-[20px] font-normal  bg-[#FF5732]  border-[#FF5732]">Apply Now</button>
                  </div>
                </div>
                  <img className="md:h-[350px] md:w-[300px] h-[300px] w-[250px] md:pr-7 object-cover object-center rounded" alt="hero" src={educloan} />
              </div>
            </section>
          </div>
        </div>
        <div className='py-12 sm:mx-28 mx-10'>
          <h1 className='text-center sm:text-[32px] text-[28px] text-[rgba(0,0,0,0.87)] font-[500]'>What is education loan?</h1>
          <div className='text-[rgba(107,107,107,107)] mt-6 sm:text-start text-center leading-8 text-[18px]'>
            <p>An education loan is a type of loan designed to help students pay for their education expenses, such as tuition fees, textbooks, and living expenses. It's usually offered at a lower interest rate than other types of loans, and repayment typically starts after the student completes their studies. The loan amount and terms depend on the student's educational program and financial need.
            </p>
          </div>
          <div>
            <div className='text-center sm:text-[32px] text-[28px] text-[rgba(0,0,0,0.87)] font-[500] mt-10'>
              How to Apply ?
            </div>
            <section className="text-gray-600 body-font">
              <div className="container px-5 pt-10 mx-auto flex flex-wrap ">
                <div className="flex flex-wrap w-full items-center justify-center ">
                  <div className="lg:w-2/5 md:w-1/2 md:pr-10 md:py-6">
                    <div className="flex relative pb-12">
                      <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                        <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                      </div>
                      <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                          <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                        </svg>
                      </div>
                      <div className="flex-grow pl-4">
                        <h2 className="font-medium  sm:text-[16px] text-[14px] text-gray-900 mb-1 tracking-wider">STEP 1</h2>
                        <p className="leading-relaxed sm:text-[16px] text-[15px]">Click on the Apply Now.</p>
                      </div>
                    </div>
                    <div className="flex relative pb-12">
                      <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                        <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                      </div>
                      <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                          <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                          <circle cx="12" cy="7" r="4"></circle>
                        </svg>
                      </div>
                      <div className="flex-grow pl-4">
                        <h2 className="font-medium title-font sm:text-[16px] text-[14px] text-gray-900 mb-1 tracking-wider">STEP 2</h2>
                        <p className="leading-relaxed sm:text-[16px] text-[15px]">Fill the form.<br />The form will ask for your document and details.</p>
                      </div>
                    </div>
                    <div className="flex relative">
                      <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                        <svg fill="none" stroke="currentColor" strokeinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                          <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                          <path d="M22 4L12 14.01l-3-3"></path>
                        </svg>
                      </div>
                      <div className="flex-grow pl-4">
                        <h2 className="font-medium title-font  sm:text-[16px] text-[14px] text-gray-900 mb-1 tracking-wider">FINISH</h2>
                        <p className="leading-relaxed sm:text-[16px] text-[15px]">Click on the Submit Button and our team will contact you.</p>
                      </div>
                    </div>
                  </div>
                  <div className='hidden md:block'><img className='' src={HomeLoanStep} width={450} height={450} /></div>
                </div>
              </div>
            </section>
            <div className='flex mc:flex-row flex-col mt-8 items-center justify-center mc:gap-14 gap-5'>
              <div>
                <button className=" md:hidden block text-[#ffffff] px-8 py-[12px] rounded-2xl text-[20px] font-normal  bg-[#FF5732]  border-[#FF5732]">Apply Now</button>
              </div>
              <div>
                <button className="md:hidden block text-[#ffffff] px-8 py-[12px] rounded-2xl text-[20px] font-normal  bg-[#FF5732]  border-[#FF5732]">Calculator</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="text-white sm:px-40 px-2 w-full py-8  bg-[#3c0050]">
        <div className="container mx-auto flex px-5 py-1 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-3 md:mb-0 items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl font-medium text-white">Documents Required
            </h1>
            <p className=" text-[16px]  py-6 leading-relaxed">You must submit these documents to avail home loan</p>
            <div className='flex flex-col gap-3'>
              <div className='flex gap-3'>
                <img src={Check_round_fill} alt="" />
                <p className="text-[17px] leading-relaxed">Aadhar Card</p>
              </div>
              <div className='flex gap-3'>
                <img src={Check_round_fill} alt="" />
                <p className="text-[17px] leading-relaxed">Pan Card</p>
              </div>
              <div className='flex gap-3'>
                <img src={Check_round_fill} alt="" />
                <p className="text-[17px] leading-relaxed">Salary Proof</p>
              </div>
            </div>
            <div className="flex justify-center">
              <button className=" text-[#ffffff] px-8 py-[12px] mt-10  rounded-2xl text-[20px] font-normal  bg-[#FF5732]  border-[#FF5732]">Apply Now</button>
            </div>
          </div>
          <div className="md:block hidden lg:max-w-lg lg:w-full md:w-1/2 w-5/6 relative bottom-5">
            <img className="object-cover object-center rounded" alt="hero" src={hero} />
          </div>
        </div>
      </section>

      <section>
        {/* <div className='py-10 text-center title-font sm:text-4xl text-3xl font-medium text-black'>Our Partners</div> */}
        <div className=''>
          <Partners />
        </div>
      </section>
      <section>
        <Stats />
      </section>
      <section className='mt-10'>
        <Footer />
      </section>
    </div>
  )
}

export default EducationLoan