import React from 'react'
import Loan from "../Components/Loan"
import carloan from "../assets/carloan.jpg"


const Carloan = () => {
  return (
    <div>

      <Loan loanname="Car Loan" getloanname="Get Car Loan" loansubheading="Unlock Your Car Dreams with Our Flexible and Affordable Car Loans" loanimg={carloan} whatisloanname="What is Car loan?" loandefinition="Cars today are a basic utility that everyone feels the need to own. You've been waiting patiently and saving for that purpose. Ruloans offers you the opportunity to own a car that you would not dream to own a decade ago. Ruloans offers tailor-made car loans with exclusive features both for salaried and self-employed persons. To get your car within your reach, talk to your Ruloans Team for constructive choices via phone, email, or chat, and apply for a Car Loan to get your Ruloans' Flexible Car Loans Advantage. Own a car you want with Ruloans team at your service!" />
    </div>
  )
}

export default Carloan