import React from 'react'
import Loan from "../Components/Loan"
import businessloan from "../assets/businessloan.png"
import bsloan from "../assets/bsloan.png"


const BusinessLoan = () => {
  return (
    <div>
      <Loan loanname="Business Loan" getloanname="Get Business Loan" loansubheading="Unlock Your Business Dreams with Our Flexible and Affordable Business Loans" loanimg={bsloan} whatisloanname="What is business loan?" loandefinition="Volatile market trends and increasing business needs cause demand for urgent immediate cash-in-hand. A Business Loan designed for this purpose gives you the advantage to avail unsecured credit at competitive interest rates.
      Whether you plan to invest in infrastructure investment or upgrade plant and machinery, a Business Loan caters to all your commercial needs." />
    </div>
  )
}

export default BusinessLoan