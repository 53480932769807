import React, { useState } from 'react'
import hamburger from '../assets/hamburger.svg'
import drawerCross from '../assets/drawerCross.svg'
import { Link } from 'react-router-dom'
import loanlogo2 from '../assets/loanlogo2.png'
const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <nav className='bg-[#ffffff]'>
      <div className='w-full mx-auto md:px-[110px]
      px-[30px] py-[20px] '>
        <div className='flex justify-between items-center'>
          <Link to="/"> <img className='z-50' src={loanlogo2} alt="Logo" /></Link>
          <div className='md:flex items-center hidden'>
            <ul className='md:flex gap-12 font-alexandria text-[16px] font-[600] cursor-pointer'>
              <li className=' hover:bg-[#F8F9FA] hover:rounded-md p-[6px]'> <a href="#products">Products</a> </li>
              <Link className=' hover:bg-[#F8F9FA] hover:rounded-md p-[6px]' to="/Calculator">Calculators</Link>
              <li className=' hover:bg-[#F8F9FA] hover:rounded-md p-[6px]'><a href="#partners">Partners</a></li>
              <Link className=' hover:bg-[#F8F9FA] hover:rounded-md p-[6px]' to="/about">About Us</Link>
            </ul>
            <button className='font-alexandria text-[#ffffff] px-6 py-2 rounded-xl text-[16px] font-normal ml-12 bg-[#FF5732] hover:bg-[#ffffff] hover:text-[#111536] border-4 border-[#FF5732]'>Apply Now</button>
          </div>
          {/* Responsive */}
          <div className='md:hidden'>
            <img src={toggle ? drawerCross : hamburger} alt="toggle" onClick={() => setToggle(!toggle)} />
            <div className={`${toggle ? "right-0" : "hidden right-[-100%]"} bg-[#ffffff] top-[85px] right-0 w-[100%]   absolute h-[100vh] z-50`}>
              <div className='px-[30px] py-[20px]'>
                <div className='gap-5'>
                  <Link to="/Homeloan"><div className=' border-[2px] p-3 rounded-lg border-[#F2F2F2] pl-6 mt-2'><a className=' p-2 font-medium' href="#houseloan">Home Loan</a></div></Link>
                  <Link to="/Carloan"><div className='mt-2 border-[2px] p-3 rounded-lg pl-6 border-[#F2F2F2]'><a className='  p-2 font-medium' href="#houseloan">Car Loan</a></div></Link>
                  <Link to="/Personalloan"><div className=' border-[2px] p-3 rounded-lg border-[#F2F2F2] mt-2 pl-6'><a className=' p-2 font-medium' href="#houseloan">Personal Loan</a></div></Link>
                  <Link to="/Educationloan"> <div className=' border-[2px] p-3 rounded-lg border-[#F2F2F2] mt-2 pl-6'><a className=' p-2 font-medium' href="#houseloan">Education Loan</a></div></Link>
                  <Link to="/Businessloan"><div className=' border-[2px] p-3 rounded-lg border-[#F2F2F2] mt-2 pl-6'><a className=' p-2 font-medium' href="#houseloan">Business Loan</a></div></Link>
                  <Link to="/Mortageloan"><div className=' border-[2px] p-3 rounded-lg border-[#F2F2F2] mt-2 pl-6'><a className=' p-2 font-medium' href="#houseloan">Mortage Loan</a></div></Link>
                  <Link to="/Constequloan">  <div className=' border-[2px] p-3 rounded-lg border-[#F2F2F2] mt-2 pl-6'><a className=' p-2 font-medium' href="#houseloan">Construction Equipment Loan</a></div></Link>
                  <Link to="/Commvloan"><div className='pl-6 border-[2px] p-3 rounded-lg border-[#F2F2F2] mt-2'><a className='p-2 font-medium ' href="#houseloan">Commerical Vehicle Loan</a></div></Link>
                  <Link to="/Goldloan"><div className='pl-6 border-[2px] p-3 rounded-lg border-[#F2F2F2] mt-2'><a className='p-2 font-medium ' href="#houseloan">Gold Loan</a></div></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
