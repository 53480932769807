import React from 'react'
import Navbar from '../Components/Navbar';
import Header from '../Components/Header';
import WhyLoanwallah from '../Components/WhyLoanwallah';
import Products from '../Container/Products';
import Stats from '../Components/Stats';
import Testmonial from './Testmonial';
import Videos from '../Components/Videos';
import Footer from '../Components/Footer'
import Partners from '../Components/Partners'

const Home = () => {
    return (
        <div>
            <Navbar />
            <Header />
            {/* <WhyLoanwallah /> */}
            <Products />
            <Stats />
            <Partners />
            <Testmonial />
            <Videos />
            <Footer />

          
            
        </div>
    )
}

export default Home