
import quote from '../assets/quote.svg'
import testperson1 from '../assets/testperson1.webp'
import testperson2 from '../assets/testperson2.webp'
import teststr from '../assets/teststr.png'
import quote2 from '../assets/quote2.svg'

const Testmonial = () => {
    return (
        <div className='my-10'>
            <div>
                <p className='esm:title titleesm'>What our customer say about us</p>
            </div>
            <div className='flex flex-wrap justify-center gap-12 mc:px-10 px-4'>
                <div className='esm:testbox testboxesm px-4 py-12'>
                    <div>
                        <img src={quote} alt="" />
                        <p className='testcontent py-6 px-4'>Neat and clean interface. No nonsense buttons/diversions. However, lacks the option to update your profile. If that is implemented, I guess for me it will deserve 5 stars.
                        </p>
                        <div className='relative esm:left-[264px] left-[200px]'>
                            <img src={quote2} alt="" />
                        </div>
                    </div>
                    <div className='flex pt-8'>
                        <img src={testperson1} alt="" />

                        <div className= 'pl-5'>
                            <h1 className='testpname'>Kunal Singh</h1>
                            <img className='h-7 w-36' src={teststr} alt="" />
                        </div>
                    </div>
                </div>
                <div className='esm:testbox testboxesm px-4 py-12'>
                    <div>
                        <div>
                            <img src={quote} alt="" />
                            <p className='testcontent py-6 px-4'>Highly recommend this for a home loan. Good interest rates, transparent process and fees. Whole process is streamlined and most of it done online. Had a great experience.
                            </p>
                            <div className='relative esm:left-[264px] left-[200px]'>
                                <img src={quote2} alt="" />
                            </div>
                        </div>
                        <div className='flex pt-8'>
                            <img src={testperson2} alt="" />

                            <div className='pl-5'>
                                <h1 className='testpname'>Tarun Sharma</h1>
                                <img className='h-7 w-36' src={teststr} alt="" />
                            </div>
                        </div>
                    </div>
                 
                </div>
            </div>
        </div>
    )
}

export default Testmonial